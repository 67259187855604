@import "variables";

// Colors
$white:  #fff;
$black:  #000;
$red:    #d9534f;
$orange: #f0ad4e;
$yellow: #ffd500;
$green:  #5cb85c;
$blue:   #0275d8;
$teal:   #5bc0de;
$pink:   #ff5b77;
$purple: #613d7c;

$brand-primary:             #88bd24; 

$brand-black:               #151615;
$brand-gray:                #f9f9f9;
$brand-gray1:                #fbfbfb;
$brand-gray2:                #f2f2f2;
$brand-shadow:              #3a3a3a;
$brand-dark:                #313131;
$brand-footer:              #333333;
$brand-copyright-footer:    #333333;
$brand-contact:             #3a3a3a;
$brand-footer-text:         #7d7d7d;


$edoc: #16b4ce;
$fileconv: #e14e26;

// Create grayscale
$gray-dark:                 #292b2c;
$gray:                      #464a4c;
$gray-light:                #636c72;
$gray-lighter:              #eceeef;
$gray-lightest:             #f7f7f7;
$gray-info: #999999;

$hr: #818383;

$btn-primary-color:              $white;
$btn-primary-bg:                 $brand-primary;
$btn-primary-border:             $btn-primary-bg;

$btn-secondary-color:            $white;
$btn-secondary-bg:               transparent;
$btn-secondary-border:           $white;
